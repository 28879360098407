<template>
  <div class="animated fadeIn container">
    <h1>망 상태</h1>
    <table class="border-table mt-4">
      <thead>
        <tr>
          <td v-for="item in providers" :key="item.id">{{ item.title }} ({{ (providerGroupInfo[item.id] || 0) |
            numberFormat }})</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="item in providers" :key="item.id">
            <select @change="changeProvider" v-bind:value="item.id + '|' + (item.tempId || item.id)">
              <option :key="option.id" v-for="option in providers" :value="item.id + '|' + option.id">{{ option.title }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
    <h1 style="margin-top:100px">3사테스트</h1>
    <table class="border-table mt-4">
      <tbody>
        <tr>
          <td>망 선택</td>
          <td>
            <select v-model="testProviderId">
              <option value="0">선택안함</option>
              <option :key="option.id" v-for="option in providers" :value="option.id">{{ option.title }}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>에러 문구</td>
          <td> <input type="text" v-model="testErrMsg" class="w-100"> </td>
        </tr>
        <tr>
          <td>SKT</td>
          <td><input type="text" v-model="sktPhoneNumbers" class="w-100"></td>
        </tr>
        <tr>
          <td>KT</td>
          <td><input type="text" v-model="ktPhoneNumbers" class="w-100"></td>
        </tr>
        <tr>
          <td>LG</td>
          <td><input type="text" v-model="lgPhoneNumbers" class="w-100"></td>
        </tr>
        <tr>
          <td>메모</td>
          <td><input type="text" v-model="testMemo" class="w-100"></td>
        </tr>
        <tr>
          <td colspan="3" class="text-right">
            <button class="btn btn-primary" @click="updateSetting">업데이트</button>
          </td>
        </tr>
      </tbody>
    </table>
    <h1>서버 상태</h1>
    <table class="border-table mt-4" id="health-table" v-if="items.length > 0">
      <thead>
        <tr>
          <td>키</td>
          <td width="200px" class="text-center">값</td>
          <td width="200px" class="text-center">최종 업데이트</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>
            <img :src="'/img/status_' + item.status + '.svg'" class="icon" alt="상태 아이콘" />
            {{ item.key }}
          </td>
          <td class="text-center">
            {{ item.value }}
          </td>
          <td class="text-center">
            {{ item.updatedAt | dateFormat }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import StatusService from '@/services/StatusService'
import SmsProviderService from '../../services/SmsProviderService'

export default {
  name: 'HealthCheck',
  data() {
    return {
      items: [],
      providers: [],
      providerGroupInfo: {},
      testProviderId: 0,
      testErrMsg: '',
      sktPhoneNumbers: '',
      ktPhoneNumbers: '',
      lgPhoneNumbers: '',
      testMemo: ''
    }
  },
  mounted() {
    document.body.classList.add('bg-white')
    this.getList()
    this.getSmsProviders();
    this.getServerSetting();
  },
  methods: {
    async getList() {
      const response = await StatusService.healthCheck()
      if (response.status == 200) {
        this.items = response.data.list
      }
    },
    async getServerSetting() {
      const response = await StatusService.getSetting()
      if (response.status == 200) {
        this.testProviderId = response.data.testProviderId
        this.testErrMsg = response.data.testErrMsg
        this.sktPhoneNumbers = response.data.sktPhoneNumbers
        this.ktPhoneNumbers = response.data.ktPhoneNumbers
        this.lgPhoneNumbers = response.data.lgPhoneNumbers
        this.testMemo = response.data.testMemo
      }
    },
    async getSmsProviders() {
      const response = await SmsProviderService.list()
      if (response.status == 200) {
        this.providers = response.data.list
        this.providerGroupInfo = response.data.groupInfo
      }
    },
    async changeProvider(e) {
      const comps = e.target.value.split('|')
      const targetProviderId = comps[0];
      const providerId = comps[1]

      await SmsProviderService.updateUsers({ targetProviderId, providerId })
    },
    async updateSetting() {
      const params = {
        testProviderId: this.testProviderId,
        testErrMsg: this.testErrMsg,
        sktPhoneNumbers: this.sktPhoneNumbers,
        ktPhoneNumbers: this.ktPhoneNumbers,
        lgPhoneNumbers: this.lgPhoneNumbers,
        testMemo: this.testMemo
      }

      const response = await StatusService.updateSetting(params)
      if (response.status == 200) {
        alert('저장 성공');
      }
    }
  },
}
</script>

<style scoped>
.icon {
  width: 20px;
}

#health-table td {
  padding: 10px 5px;
}
</style>
